import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { CartService, WishlistService } from "../../services/api/general";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import "../../styles/custom.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import IconButton from "@mui/material/IconButton";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import VideoPlayer from "../../components/VideoPlayer";
import ProductImage from "./productImage";
import { useWindowSize } from "../../hooks/uiHooks";
import {
  MAX_PRODUCT_VISIBLE_DESCRIPTION_LENGHT,
  MAX_WINDOW_WIDTH,
  LOGIN_SSO_ROUTE,
  NODE_ENV
} from "../../constants/";
import LoginSSO from "../../utils/LoginSSO";
import { useUserContext } from "../../hooks/userHooks";

function ProductDetails(props) {
  const [value] = useState(4.5); // this sets the rating
  const [inCart, setInCart] = useState(false);
  const [inWishlist, setInWishlist] = useState(false);
  const {
    user,
    isLoggedIn,
    cart,
    updateCart,
    wishlist,
    addToWishlist,
    removeFromWishlist,
    setInstantPurchase
  } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [currImage, setCurrImage] = useState(0);
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const [ssoActivated, setSsoActivated] = useState(0);
  const [ssoRoute, setSsoRoute] = useState("");
  const [isSeeMore, setIsSeeMore] = useState(true);

  const toggleSeeMore = () => {
    setIsSeeMore(!isSeeMore);
  };

  const handlePurchase = () => {
    let cartProduct = {
      product: {
        name: props.product.name,
        description: props.product.description,
        price: props.product.price,
        picture: props.product.media?.[0].frame || props.product.media?.[0].uri,
        id: props.product.id,
        seller: props.product.seller,
        subcategoryId: props.product.subcategoryId
      },
      qty: 1
    };

    setInstantPurchase([cartProduct]);
    localStorage.setItem("LOCATION_PREV_PATH", window.location.pathname);
    navigate("/checkout");
  };

  const addItemToCart = () => {
    if (inCart) {
      enqueueSnackbar("Item already in cart", { variant: "error" });
    } else {
      setInCart(true);
      let product = {
        description: props.product.description,
        id: props.product.id,
        name: props.product.name,
        picture: props.product.media?.[0].frame || props.product.media?.[0].uri,
        price: props.product.price,
        seller: props.product.seller,
        subcategoryId: props.product.subCategoryId
      };
      updateCart(product, 1);
      enqueueSnackbar("Item added!", { variant: "success" });
    }
  };

  const removeItemFromCart = () => {
    if (cart === null) {
      setInCart(false);
    } else {
      let product = {
        description: props.product.description,
        id: props.product.id,
        name: props.product.name,
        picture: props.product.media?.[0].frame || props.product.media?.[0].uri,
        price: props.product.price,
        seller: props.product.seller,
        subcategoryId: props.product.subCategoryId
      };
      updateCart(product, 0);
      setInCart(false);
    }
  };

  const addItemToWishlist = () => {
    if (inWishlist) {
      enqueueSnackbar("Item already in wishlist", { variant: "error" });
    } else {
      setInWishlist(true);
      let product = {
        description: props.product.description,
        id: props.product.id,
        name: props.product.name,
        picture: props.product.media?.[0].frame || props.product.media?.[0].uri,
        price: props.product.price,
        seller: props.product.seller,
        subcategoryId: props.product.subCategoryId
      };
      addToWishlist(product);
      enqueueSnackbar("Item added to Wishlist!", { variant: "success" });
    }
  };

  const loginButtonAction = (route) => {
    if (NODE_ENV.toLowerCase() === "production") {
      setSsoRoute(LOGIN_SSO_ROUTE);
      setSsoActivated((ssoActivated) => ssoActivated + 1);
    } else {
      navigate(route);
    }
  };

  const removeItemFromWishlist = () => {
    if (wishlist === null) {
      setInWishlist(false);
    } else {
      removeFromWishlist(props.product.id);
      setInWishlist(false);
    }
  };

  useEffect(() => {
    if (cart === null) {
      setInCart(false);
    } else {
      console.log("Searching in cart...");
      const cartItem =
        cart.find((item) => item.product.id === props.product.id) || null;

      console.log("cartItem:");
      console.log(cartItem);

      if (cartItem) {
        setInCart(true);
      } else {
        setInCart(false);
      }
    }

    if (wishlist === null) {
      setInWishlist(false);
    } else {
      console.log("Searching in wishlist...");
      const wishlistItem =
        wishlist.find((item) => item.product.id === props.product.id) || null;

      console.log("wishlistItem:");
      console.log(wishlistItem);

      if (wishlistItem) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [user.id, props.product.id, cart, wishlist]);

  const styles = {
    purchaseButtons: {
      backgroundColor: "#FF9900",
      fontFamily: "'Inter', sans-serif",
      display: "flex",
      marginTop: "12px",
      justifyContent: "flex-end",
      flexDirection: "row"
    },
    flexContainer: {
      display: "flex",
      paddingTop: "30px"
    },
    flexProduct: {
      flexWrap: "wrap"
    },
    flexImage: {
      paddingBottom: "20px"
    },
    flexDetails: {
      paddingLeft: "30px"
    },
    videoIcon: {
      fontSize: 50,
      display: "flex"
    },
    productView: {
      maxWidth: windowWidth > MAX_WINDOW_WIDTH ? 363 : windowWidth,
      maxHeight: windowWidth > MAX_WINDOW_WIDTH ? 302 : 282
    }
  };

  return (
    <div className="product-container">
      <LoginSSO ssoActivated={ssoActivated} ssoRoute={ssoRoute} />
      <div className="image-container">
        <div
          className="product-view"
          id="product-view"
          style={styles.productView}>
          <div>
            {props.product.media?.[currImage].mimetype.match(/^image\/.*/) ? (
              <ProductImage uri={props.product.media?.[currImage].uri} />
            ) : (
              <VideoPlayer
                uri={props.product.media?.[currImage].uri}
                width={windowWidth > MAX_WINDOW_WIDTH ? 333 : 348}
                height={windowWidth > MAX_WINDOW_WIDTH ? 222 : 180}
                controls={true}
              />
            )}
          </div>
        </div>
        <div className="product-icons">
          <ImageList
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: {
                sm: "row",
                md: "row"
              }
            }}
            gap={8}>
            {props.product.media ? (
              props.product.media.map((item, index) => {
                return index < 10 ? (
                  <ImageListItem key={item.filename}>
                    <div
                      key={index}
                      onClick={() => setCurrImage(index)}
                      className="product-row"
                      style={{
                        border:
                          index === currImage
                            ? "1px solid var(--tertiary-colour)"
                            : ""
                      }}>
                      {!!item.mimetype.match(/^image\/.*/) ? (
                        <img src={item.uri} alt="" />
                      ) : (
                        <IconButton
                          style={{
                            padding: 0
                          }}>
                          {item.frame ? (
                            <img
                              style={{ width: "65px", height: "52px" }}
                              src={item.frame}
                              alt="videoFrame"
                              key={item.frame}
                            />
                          ) : (
                            <PersonalVideoIcon
                              style={styles.videoIcon}></PersonalVideoIcon>
                          )}
                        </IconButton>
                      )}
                    </div>
                  </ImageListItem>
                ) : null;
              })
            ) : (
              <ImageListItem></ImageListItem>
            )}
          </ImageList>
        </div>
      </div>
      <div className="product-info">
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              sm: "row",
              md: "column"
            },
            flexWrap: "wrap",
            flexGrow: 1,
            justifyContent: "center",
            width: ["80%", "80%", "60%", "35%"],
            marginLeft: "1rem"
          }}>
          <Box>
            <Typography
              fontFamily="'Inter', sans-serif;"
              variant="h4"
              fontWeight="400"
              noWrap
              component="div"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                whiteSpace: "normal",
                paddingRight: "1rem"
              }}>
              {props.product?.name ?? "-"}
            </Typography>
            <StyledRating
              name="read-only"
              value={value}
              precision={0.5}
              readOnly
            />
            <Typography
              fontFamily="'Inter', sans-serif;"
              variant="h4"
              fontWeight="700"
              noWrap
              component="div">
              ${props.product?.price ?? "-"}
            </Typography>
            <Typography
              fontFamily="'Inter', sans-serif;"
              variant="p"
              fontWeight="400"
              component="div"
              sx={{
                maxWidth: 350
              }}>
              {isSeeMore ? (
                <ReactMarkdown>
                  {props.product?.description.substr(
                    0,
                    MAX_PRODUCT_VISIBLE_DESCRIPTION_LENGHT
                  )}
                </ReactMarkdown>
              ) : (
                <ReactMarkdown>{props.product?.description}</ReactMarkdown>
              )}
              {props.product?.description.length >
                MAX_PRODUCT_VISIBLE_DESCRIPTION_LENGHT && (
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                    fontWeight: "bold",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline"
                    }
                  }}
                  onClick={toggleSeeMore}>
                  {isSeeMore ? "... See more" : " Show less"}
                </Box>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: {
                sm: 5,
                md: 0
              }
            }}>
            <Button
              variant="contained"
              style={styles.purchaseButtons}
              onClick={handlePurchase}>
              PURCHASE
            </Button>
            {inCart ? (
              <Button
                variant="contained"
                style={styles.purchaseButtons}
                startIcon={<CheckIcon />}
                onClick={() => removeItemFromCart()}>
                In Cart
              </Button>
            ) : !isLoggedIn ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "lightgray",
                    marginTop: "10px"
                  }}
                  onClick={() => {
                    loginButtonAction(
                      `/signin?return-url=${window.location.pathname}`
                    );
                  }}>
                  Sign In To Use Cart
                </Button>
                <br />
              </>
            ) : (
              <Button
                variant="contained"
                style={styles.purchaseButtons}
                onClick={() => addItemToCart()}>
                ADD TO CART
              </Button>
            )}
            {inWishlist ? (
              <Button
                variant="contained"
                style={styles.purchaseButtons}
                startIcon={<CheckIcon />}
                onClick={() => removeItemFromWishlist()}>
                In Wishlist
              </Button>
            ) : !isLoggedIn ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "lightgray",
                    marginTop: "10px"
                  }}
                  onClick={() => {
                    loginButtonAction(
                      `/signin?return-url=${window.location.pathname}`
                    );
                  }}>
                  Sign In To Use Wishlist
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                style={styles.purchaseButtons}
                onClick={() => addItemToWishlist()}>
                ADD TO WISHLIST
              </Button>
            )}
          </Box>
        </Box>
      </div>
      <Divider variant="middle" />
    </div>
  );
}

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#3E3E3E"
  }
});

export default ProductDetails;
