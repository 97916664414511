import { useState, useEffect } from "react";
import { LoginService } from "../services/api/general";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  LOGIN_SSO_URL,
  LOGIN_SSO_ROUTE,
  LOGIN_SSO_WINDOW_NAME,
  LOGIN_SSO_WINDOW_WIDTH,
  LOGIN_SSO_WINDOW_HEIGHT,
  SIGNUP_SSO_WINDOW_HEIGHT,
  LOGIN_SSO_RESPONSE_TYPE
} from "../constants";
import { useUserContext } from "../hooks/userHooks";

export default function LoginSSO(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { authenticateUser } = useUserContext();
  const [activeEventListener, setActiveEventListener] = useState(0);

  function popupCenterParams(width, height) {
    return {
      left: window.screen.width / 2 - width / 2,
      top: window.screen.height / 2 - height / 2
    };
  }

  async function finishLoginSSO(popup, message, eventData) {
    popup.close();
    console.log("Event Data: ", eventData);
    enqueueSnackbar(message.title, { variant: message.type });
    window.location.reload();
  }

  async function handleLogin(route) {
    console.log("handle login", route);
    const windowHeight =
      route === LOGIN_SSO_ROUTE
        ? LOGIN_SSO_WINDOW_HEIGHT
        : SIGNUP_SSO_WINDOW_HEIGHT;

    const popupParams = popupCenterParams(LOGIN_SSO_WINDOW_WIDTH, windowHeight);
    const loginWindow = window.open(
      `${LOGIN_SSO_URL}/${route}`,
      LOGIN_SSO_WINDOW_NAME,
      `width=${LOGIN_SSO_WINDOW_WIDTH},
      height=${windowHeight},
      toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no,
      top=${popupParams.top}, left=${popupParams.left}`
    );

    // Loading a usual Login Form
    if (!loginWindow || loginWindow.closed) {
      navigate("/signin");
    }
    // Loading only the one Event Listener to prevent the repeating actions
    if (activeEventListener === 1) {
      return;
    }
    setActiveEventListener(1);

    window.addEventListener("message", async function (event) {
      console.log("EventListener event:");
      console.log(event);
      // Make sure we only handle message which comes from the expected origin (iLiveMyLife)
      if (event.origin !== LOGIN_SSO_URL) {
        return;
      }

      const eventDataType = JSON.parse(event?.data?.type);
      if (eventDataType?.name === "loginError") {
        await finishLoginSSO(
          loginWindow,
          {
            title: "Login Failed",
            type: "error"
          },
          eventDataType
        );
        return;
      } else if (eventDataType?.name === "signupError") {
        await finishLoginSSO(
          loginWindow,
          {
            title: "Signup Failed",
            type: "error"
          },
          eventDataType
        );
        return;
      } else if (eventDataType?.name === LOGIN_SSO_RESPONSE_TYPE) {
        // Process the authentication token here and close the popup window
        const response = await LoginService._loginSSO(event.data.data);

        if (!response) {
          eventDataType.name = "LoginIssue";
          eventDataType.message = "authentication token issue";
          eventDataType.status = "";
          await finishLoginSSO(
            loginWindow,
            {
              title: "Issue logging in. Please try Again in a moment.",
              type: "warning"
            },
            eventDataType
          );
          return;
        }

        console.log("Token is coming from data:");
        console.log(response.data);
        if (authenticateUser(response.data.token)) {
          await finishLoginSSO(
            loginWindow,
            {
              title: "Successfully Logged In",
              type: "success"
            },
            eventDataType
          );
        } else {
          eventDataType.name = "LoginIssue";
          eventDataType.message = "token validation issue";
          eventDataType.status = "";

          await finishLoginSSO(
            loginWindow,
            {
              title: "Issue logging in. Token Validation Failed.",
              type: "error"
            },
            eventDataType
          );
        }
      }
    });
  }

  useEffect(() => {
    if (!!props.ssoActivated && !!props.ssoRoute) {
      handleLogin(props.ssoRoute);
    }
  });

  return;
}
