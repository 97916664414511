import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { validate } from "email-validator";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ReactLoading from "react-loading";
import { login } from "../../utils/auth";
import { useUserContext } from "../../hooks/userHooks";

// line 25 add the basic needs URL in href
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link color="inherit" href="">
        Basic Needs
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF9900",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#3E3E3E",
      contrastText: "#FFFFFF"
    }
  }
});

export default function SignInForm(props) {
  const navigate = useNavigate();
  const { authenticateUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loginRequested, setLoginRequested] = useState(false);

  function handleSubmit(event) {
    setLoginRequested(true);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const email = formData.get("email");
    const password = formData.get("password");

    if (!validate(email)) {
      handleLoginError("Email Format Incorrect", "error");
      console.error("Email formatting error");
      return;
    } else {
      const returnUrl = props?.returnUrl ?? "/";
      login(
        email,
        password,
        () => onSuccess(returnUrl),
        handleLoginError,
        authenticateUser
      ).then(() => {
        setLoginRequested(false);
      });
    }
  }

  function onSuccess(returnUrl) {
    navigate(returnUrl, { replace: true });
    enqueueSnackbar("Successfully Logged In", { variant: "success" });
  }

  function handleLoginError(message, snackBarVariant) {
    const validVariants = ["error", "info", "success", "warning", "default"];
    if (!validVariants.includes(snackBarVariant)) {
      throw new Error("Invalid Snackbar variant provided");
    }

    enqueueSnackbar(message, { variant: snackBarVariant });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Typography
            fontFamily="'Inter', sans-serif;"
            variant="h4"
            fontWeight="700"
            noWrap
            component="div"
            paddingBottom="10px"
            color="var(--tertiary-colour)"
            sx={{ flexGrow: 2 }}>
            Basic Needs.
          </Typography>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1rem",
                width: "100%",
                height: "3rem"
              }}>
              {loginRequested ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    width: "50%",
                    height: "80%"
                  }}>
                  <ReactLoading
                    type={"spin"}
                    color={"#FF9900"}
                    width="20%"
                    sx={{ padding: "2rem" }}
                  />
                </Box>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Sign In
                </Button>
              )}
            </Box>

            <Grid container>
              <Grid item xs>
                <Link
                  href="https://app.ilivemylife.io/recoverPassword"
                  variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href={"/signup"} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
