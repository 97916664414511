import { Button, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import Header from "../components/NavigationBar/Header";
import Product from "../components/Product";
import SubCategoryTile from "../components/SubCategoryTile";
import { CategoryContext } from "../context/Contexts";
import { CategoriesService, ProductsService } from "../services/api/general";
import * as Router from "react-router-dom";
import { alpha } from "@mui/material/styles";
import productImage from "../components/Checkout/ProductImage";
import ProductScroller from "../components/ProductScroller";
import { useUserContext } from "../hooks/userHooks";
import { UserContext } from "../context/UserProvider";

function generateSubCategories(subCategories) {
  return (
    <Box
      id="subcategory-container"
      sx={{
        width: "auto",
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem"
      }}>
      {subCategories.map((item) => {
        return <SubCategoryTile item={item} key={item.id} />;
      })}
    </Box>
  );
}

function Category() {
  const [categories] = useContext(CategoryContext);
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [done, setDone] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const { isLoggedIn } = useUserContext();

  const params = useParams();

  useEffect(
    () => {
      setDone(false);
      const categoryName = params?.categoryName ?? "";
      const categoryId = params?.categoryId ?? "";
      if (categoryName === "" || categoryId === "") return;

      // const selectedCategory = categories?.all.find(
      //   (option) => option.name === categoryName
      // );

      ProductsService._getCategoriesProducts(params?.categoryId).then((res) => {
        let formattedProducts = [];
        res.forEach((item) => {
          if (!!item.description) {
            let fields = JSON.parse(item.description);
            if (fields.price > 0) {
              let newProduct = {
                name: fields.name,
                description: fields.description,
                price: fields.price,
                picture: productImage(fields),
                id: item.id
              };
              formattedProducts.push(newProduct);
            }
          }
        });
        setProducts(formattedProducts);
        setDone(true);
      });

      CategoriesService._getSubcategories(params?.categoryId).then((res) => {
        let formattedSubcategories = res.map((item) => {
          let newSubcat = {
            name: item.title,
            id: item.id
          };
          return newSubcat;
        });
        setSubCategories(formattedSubcategories);
      });
    },
    [categories, params?.categoryName, params?.categoryId],
    done
  );

  const remainingProducts = products.map((item) => {
    return (
      <Product
        key={item.id}
        id={item.id}
        name={item.name}
        description={item.description}
        price={item.price}
        picture={item.picture}
      />
    );
  });

  return (
    <div>
      <Header />
      <MainContainer>
        {!done ? (
          <ReactLoading
            type={"spin"}
            color={"#FF9900"}
            height={100}
            width={100}
            className={"loader"}
          />
        ) : !products?.length ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "30vh"
            }}>
            <Typography
              variant="h7"
              fontWeight={400}
              sx={{ height: "2rem", textAlign: "center" }}>
              Sorry! No Items Available.
            </Typography>
            <br />
            <br />
            <Typography
              variant="h7"
              fontWeight={420}
              sx={{ height: "2rem", textAlign: "center" }}>
              Have something you'd like to sell?
            </Typography>
            <br />
            <br />
            <Button
              component={Router.Link}
              to={isLoggedIn ? `/createproduct` : `/signin`}
              sx={{
                borderRadius: "none",
                border: "1px solid",
                color: "darkText.main",
                width: "10rem",
                margin: "auto",
                ":hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.tertiary.main, 0.8)
                }
              }}>
              Add A Product
            </Button>
          </Box>
        ) : (
          <React.Fragment>
            <Collapse
              onMouseOver={() => setShowSubCategories(true)}
              onMouseOut={() => setShowSubCategories(false)}
              sx={{
                zIndex: "3",
                position: "relative"
              }}
              in={showSubCategories}
              collapsedSize={"4rem"}>
              <Box
                id="subcatbox"
                sx={{
                  padding: "1rem",
                  // height: "40vh",
                  backgroundColor: "secondary.main"
                }}>
                <Box
                  sx={{
                    display: "flex",
                    // height: "40vh",
                    backgroundColor: "secondary.main",
                    marginBottom: "1rem"
                  }}>
                  <Typography
                    to="/"
                    fontFamily="'Inter', sans-serif;"
                    variant="h6"
                    fontWeight="600"
                    noWrap
                    component="div"
                    color="var(--tertiary-colour)"
                    sx={{ flexGrow: 2 }}>
                    View SubCategories.
                  </Typography>
                  <Button
                    onClick={() => setShowSubCategories(!showSubCategories)}>
                    {showSubCategories ? "Hide" : "Show"}
                  </Button>
                </Box>

                {!subCategories?.length ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#FF9900"}
                    height={100}
                    width={100}
                    className={"loader"}
                  />
                ) : (
                  generateSubCategories(subCategories)
                )}
              </Box>
            </Collapse>
            <Typography
              to="/"
              fontFamily="'Inter', sans-serif;"
              variant="h6"
              fontWeight="600"
              noWrap
              component="div"
              color="black"
              sx={{ flexGrow: 2, marginLeft: 2, marginTop: 2 }}>
              {params?.categoryName}
            </Typography>
            {/* <ProductScroller products={products} /> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                padding: "1rem",
                justifyContent: "center"
              }}>
              {remainingProducts}
            </Box>
          </React.Fragment>
        )}
      </MainContainer>
    </div>
  );
}

export default Category;
