import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { validate } from "email-validator";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { login } from "../../utils/auth";
import { useUserContext } from "../../hooks/userHooks";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link color="inherit" href="">
        Basic Needs
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF9900",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#3E3E3E",
      contrastText: "#FFFFFF"
    }
  }
});

export default function SignUpForm() {
  const navigate = useNavigate();
  const { authenticateUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
      name: data.get("firstName") + data.get("lastName")
    });

    let email = data.get("email");
    let password = data.get("password");
    let name = data.get("firstName") + " " + data.get("lastName");

    if (!validate(email)) {
      handleLoginError("Email Format Incorrect", "error");
      return;
    } else {
      signup(email, password, name);
    }
  };

  async function signup(email, password, name) {
    const res = await Axios.post("https://api.ilivemylife.io/api/v1/users", {
      fullName: name,
      email: email,
      password: password
    });
    if (!res) {
      console.error("Failed Login");
      handleLoginError("Signup Failed", "error");
      return;
    } else {
      const returnUrl = "/";
      login(
        email,
        password,
        () => onSuccess(returnUrl),
        handleLoginError,
        authenticateUser
      );
    }
  }

  function onSuccess(returnUrl) {
    navigate(returnUrl, { replace: true });
    enqueueSnackbar("Successfully Logged In", { variant: "success" });
  }

  function handleLoginError(message, snackBarVariant) {
    const validVariants = ["error", "info", "success", "warning", "default"];
    if (!validVariants.includes(snackBarVariant)) {
      throw new Error("Invalid Snackbar variant provided");
    }

    enqueueSnackbar(message, { variant: snackBarVariant });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Typography
            to="/"
            fontFamily="'Inter', sans-serif;"
            variant="h4"
            fontWeight="700"
            noWrap
            component="div"
            paddingBottom="10px"
            color="var(--tertiary-colour)"
            sx={{ flexGrow: 2 }}>
            Basic Needs.
          </Typography>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href={"/"} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
